@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  width: 100%;
}

div.content {
  display: flex;
  flex-direction: column;
  width: 360px;
  min-height: 392px;
}

.account_container {
  margin-left: 25px;
}

.inner_content {
  display: flex;
  flex-direction: column;
}

.date_content {
  height: 63px;
  padding-left: 83px;
  justify-content: space-between;
  margin-bottom: 11px;
}

.collection_date_content {
  margin-top: 5px;
  padding-left: 27px;
}

.postcode_section {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex: 1;

  .postcode_field {
    width: 68px !important;
  }

  .find_button {
    min-width: 94px;
    height: 26px;
    background-color: $light_black;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: $white;
  }

  .or_word {
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.error {
  color: $red;
  margin-left: 20px;
}
