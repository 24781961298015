@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.fieldContainer {
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
  margin-bottom: 11px;
  .field {

    select {
      display: block;
      padding: 0 25px 0 5px;
      height: 1.625rem;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("../../assets/icons/down-arrow.svg");
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 1.2em auto, 100%;
      border-radius: 0;
      font-size: 12px;
    }

    .invalid {
      border: 1px solid $red;
    }
    .select::-ms-expand {
      display: none;
    }
    select:focus {
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }
    select option {
      font-weight: normal;
      font-size: 12px;
    }
    select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  div.field {
    width: 11.62rem;
  }

  span.required {
    color: $bright_red;
    padding-right: 5px;
  }

  .fieldLabel {
    color: $gray_brown;
    font-size: 12px;
  }
}
