@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.typed_label {
    display: flex;
    width: 100%;
    color: $gray_brown;
    cursor: default;
    margin: 0;

    input {
        box-shadow: none;
    }

    input[type="radio"] {
        margin-right: 10px;
        margin-top: 2px;
    }
}
