.notification_details {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .vertical_margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input_container {
    width: 350px !important;
  }

  .input {
    width: 229px !important;
  }
}
