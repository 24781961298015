@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.filter_form {
  width: 100%;

  .input {
    @extend %form_input_width;
  }

  .col_type {
    width: 250px;
  }

  .date_input_label {
    margin-bottom: 0;
  }
}
