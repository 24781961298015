@import "~@dpdgroupuk/mydpd-ui/styles/themes";

.day-picker {
  display: flex;
  flex-direction: column;
  font-size: 12px;

  .selected-date {
    height: 18px;
  }

  .days-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;

    .day-column {
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .day-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 39px;
      height: 30px;
    }

    .day-body {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      width: 39px;
      height: 39px;
      border-radius: 20px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:hover:not(.active) {
        background-color: $light_gray;
      }

      &.active {
        color: $white;
        @include themify($themes) {
          background-color: themed("brand");
        }
      }
    }
  }
}
