@import "~@dpdgroupuk/mydpd-ui/styles/themes";

.cc-modal-header {
  display: flex;
  @include themify($themes) {
    background-color: themed("brand_dark");
  }

  color: $white;
  height: 34px;
  padding-left: 10px;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.4;
}
