@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .error_text {
    margin-top: 20px;
    white-space: pre-wrap;
    text-align: center;
  }

  .error_img {
    background-image: url("../../../../assets/icons/group_box.svg");
    background-repeat: no-repeat, repeat;
    width: 112px;
    height: 125px;
    margin-top: 20px;
  }
}
