.container {
  margin: 0;

  & div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}