@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.postcode_section {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex: 1;

  .postcode_field {
    width: 70px !important;
  }

  .find_button {
    min-width: 94px;
    height: 26px;
    background-color: $light_black;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: $white;
    &:disabled {
      background-color: $gray;
    }
  }

  .or_word {
    margin-top: 4px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
  }
}
