@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.title {
  height: 38px;
  color: black;
  background-color: #d1d1d1!important;
}

.container {
  background-color: #ececec;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 10px;

  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
    
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;

    .submit_button {
      margin-left: 15px;
    }
  }

  .error {
    height: 18px;
    font-size: 13px;
    color: $red;
    text-align: end;
    margin-top: 10px;
  }
}
