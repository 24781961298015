@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  width: 257px;
  border: 0;

  .text {
    line-height: 1.38;
    letter-spacing: 0.41px;
    text-align: center;
    color: $black;
  }
}
