@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.multilineFieldContainer {
  margin-bottom: 4px !important;
}

.fieldContainer {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 11px;

  .fieldLabelContainer {
    flex: 1;
  }

  .fieldLabel {
    color: $gray_brown;
    padding: 3px 0;
    font-size: 12px;
  }
  .field {
    input {
      width: 100%;
      height: 1.625rem;
      padding: 0 0 0 5px;
      font-size: 12px;
    }

    .invalid {
      border: 1px solid $red;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
    textarea {
      padding: 0.5rem;
      width: 100%;
      min-width: 11.62rem;
      font-size: 12px;
      resize: none;
    }
  }

  div.field {
    width: 11.62rem;
    position: relative;
  }

  span.required {
    color: $bright_red;
    padding-right: 5px;
  }
}
