@import "~@dpdgroupuk/mydpd-ui/styles/variables";

#postcode-finder-modal {
  width: 610px;
  max-width: 610px;
  height: 390px;

  .modal-content {
    border: none;
  }

  .postcode-finder-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 19px 0 14px 0;
    overflow: hidden;

    .left-content,
    .right-content {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }
}
