@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  width: 610px;
  height: 350px;
  background-color: $white;
  flex-direction: column;
  display: flex;
  border-radius: 5px;

  .content {
    display: flex;
    flex: 1;
  }

  .img {
    object-fit: contain;
    max-height: 286px;
    width: 100%;
  }
}
