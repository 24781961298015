@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.results_panel {
  height: 100%;
  border: none;

  .results_title {
    height: 24px;
    width: 100%;
  }

  .results_body {
    height: 300px;
    overflow-y: auto;
  }
}
