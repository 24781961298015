@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.address_list {

  .result_list_item {
    padding: 8px 10px;
    cursor: pointer;

    p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.19px;
      color: $light_black;
      margin: 0;
    }
    &:nth-child(even) {
      background: #f5f5f5;
    }
    &:nth-child(odd) {
      background: #e6e6e6;
    }
  }
}
