@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  width: 110px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light_gray;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $black_pearl;
    color: $white;
  }

  &:hover .title {
    color: $white;
  }

  .title {
    text-align: center;
    font-size: 13px;
    color: $friar_grey;
    line-height: 16px;
    letter-spacing: -0.2px;
    margin-top: 10px;
  }

  .amount {
    font-weight: bold;
    font-size: 30px;
    line-height: 16px;
    letter-spacing: -0.47px;
    margin-top: auto;
    padding-bottom: 20px;
  }
}

.disabled {
  cursor: auto;
  pointer-events: none;

  &:hover {
    background-color: $light_gray;
    color: $black_pearl;
  }

  &:hover .title {
    color: $friar_grey;
  }
}
