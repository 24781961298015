.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  padding: 10px;
  background-color: lightgrey;
  font-size: 12px;
  cursor: pointer;

  .icon_container {
    width: 40px;
    margin-right: 10px;
    text-align: center;
  }
}

.card_disabled {
  opacity: .5;
  cursor: default;
}