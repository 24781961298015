@import "~@dpdgroupuk/mydpd-ui/styles/themes";

.nav-bar-form {
  display: flex;
  padding: 8px 0;
  .search {
    cursor: text;
    margin: 0;
    font-size: 9px;
    width: 142px !important;

    .input-container {
      margin: 0 !important;
    }

    input {
      height: 22px !important;
    }
  }

  .dropdown-container {
    margin: 0;
    width: 116px;

    select {
      height: 22px !important;
      width: 106px !important;
    }
  }

  .search-submit {
    @include themify($themes) {
      background: themed("brand")
        url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUY2Q0YxMDdFQkVDMTFFNEFERkREOUUyOUMwMjk2MjAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUY2Q0YxMDhFQkVDMTFFNEFERkREOUUyOUMwMjk2MjAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRjZDRjEwNUVCRUMxMUU0QURGREQ5RTI5QzAyOTYyMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRjZDRjEwNkVCRUMxMUU0QURGREQ5RTI5QzAyOTYyMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pv5BkgEAAAEBSURBVHjajNIxawIxGMbxoAUHxbFIR63oIhSK4FfoVNRVxw6urq79CN1LB+kHuKlroRQKDioK6uTiDYKtCCKFmv6D7+kZ7tAXfoS7N89dEqK01ipAAi10sYaLNm69OUGhFD71rpb4wEief1ELCkbgyKQnXMr7KO7xjQ0KdrAkISdkC2Xpv9iNpjSqIUGzoqkRUceVkPFHBdcWCzPPDg5lLIUEr5DHxF5KEX+Y48bqmQN6la00/I0sBvpQCzyigge8y/s3xLxQGhN9up6RNJkL1puDg2vfXmaoI44MVvhCfz+DdM/6quu/WmHMqU59f3Jxh446VXI3zU0Zn/Mnz78AAwCWGOLMZtlqngAAAABJRU5ErkJggg==")
        center no-repeat;
    }
    height: 22px;
    width: 22px;
    border: none;
  }
}
