@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 24px;

    .content {
      width: 10.1rem;
      height: 1.625rem;
    }

    .input {
      width: 100%;
      padding: 0 0 0 5px;
      margin: 0;
      height: 1.625rem;
      font-size: 12px;
    }

    .invalid {
      border: 1px solid $red;
    }
  }

  .label {
    color: $gray_brown;
    cursor: pointer;
    font-size: 12px;
  }

.icon {
  position: relative;

  &::after {
    content: url("./Calendar.svg");
    position: absolute;
    right: -24px;
    top: 4px;
  }

}

.icon_disabled {
    opacity: .5;
}
