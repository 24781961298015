.container {
  position: relative;
  margin: 10px 0;

  .track_img {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 68px;
    z-index: 2;
  }
  .root {
    height: 9px;
    border-radius: 5px;
  }
  .bar1 {
    background: #808285;
  }
  .bar2 {
    background: #d8dde6;
  }
  .bar3 {
    background: #76b55a;
    animation: none;
  }
  .time {
    position: absolute;
    color: #1d9ad0;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 1.3px;
    top: -10px;
  }
  .time_end {
    right: 0;
    transform: translate(10px, -100%);
  }
  .time_start {
    transform: translate(-50%, -100%);
  }
  .time_start_shift {
    padding-right: 32px;
  }
}
