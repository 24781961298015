@import "~@dpdgroupuk/mydpd-ui/styles/themes";

.safe-place-view {
  display: flex;
  flex-direction: column;

  .header {
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .bottom-margin {
    margin-bottom: 15px;
  }

  .safe-place-row {
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;

    .hidden {
      position: fixed;
      left:-9999px;
    }

    .icon {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    input {
      box-shadow: none;
      visibility: hidden;
    }

    input[type='radio']:after {
      width: 17px;
      height: 17px;
      position: relative;
      background-color: #ececec;
      content: '';
      display: inline-block;
      visibility: visible;
      border-radius: 9px;
      border: solid 1px #d1d1d1;
    }

    input[type='radio']:checked:after {
      @include themify($themes) {
        background-color: themed("brand");
        border: solid 1px themed("brand");
      }
      box-shadow: inset 0px 0px 0px 4px #ececec;
    }

    svg {
      cursor: pointer;
    }

    .title {
      font-size: 14px;
      width: 128px;
      margin-left: 10px;
    }
  }

  input,
  .icon,
  .image {
    cursor: pointer;
  }
}