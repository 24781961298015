.option_container {
  margin-bottom: 35px;
  justify-content: flex-start;

  .option {
    width: 393px !important;
  }
}

.date_picker_container {
  width: 336px;
}
