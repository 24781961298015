@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.label {
    display: flex;
    align-items: center;
    color: $gray_brown;
    cursor: pointer;
    input {
        margin-right: 15px;
        box-shadow: none;
    }
}