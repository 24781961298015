
.agreement {
  margin-top: 20px;
  margin-bottom: -55px;

  .text_container {
    margin-left: 60px;
  }
  .text {
    color: black;;
    font-size: 13px;
  }
}
