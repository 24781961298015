// @import "../../assets/stylesheets/variables";

.day_picker_with_instruction {
  display: flex;
  flex-direction: column;
  font-size: 12px;

  .title {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .instruction {
    margin-top: 30px;
    margin-bottom: 8px;
    width: 100% !important;
  }
}