.container {
  padding-bottom: 14px;

  .tracking_status {
    font-size: 25px;
    line-height: 1.39;
    letter-spacing: -0.21px;
  }

  .customer_logo {
    max-width: 300px;
    max-height: 60px;
  }
}
